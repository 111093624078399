.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 5s linear;
  height: 55vmin;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: #8d1833;
}

#header-menu{
  display:flex;
  width: 100%;
  margin: 0;
  padding: 10px;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  font-size: 14px;
}
#header-menu li{
  list-style-type: none;
  flex-shrink: 0;
  margin-left: auto;
}
#header-menu li:nth-child(1) {
  margin-left: 0;
}
#header-menu li a,
#header-menu li button{
  margin: 0 0 10px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  25%{
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.btn {
    display: inline-block;
    font-weight: bold;
    position: relative;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    text-decoration: none;
}

.btn::before {
    content: "";
    position: absolute;
    right: 15px;
    top: 50%;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    border-bottom: 3px solid rgba(46, 192, 221,0.5);
    border-right: 3px solid rgba(46, 192, 221,0.5);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: border-box;
}

.btn-blue {
    position: relative;
    padding: .6em 1.8em .6em 1.4em;
    font-size: 14px;
    color: #2ec0dd;
    display: inline-block;
    background: #fff;
    border: 2px solid #2ec0dd;
    border-radius: 50px;
    box-shadow: none;
    font-weight: 700;
}

.btn-blue:hover {
    background: #2ec0dd;
    border: 2px solid #2ec0dd;
    color: #fff;
}
.btn-link{
    padding: 0;
    font-size: inherit;
    background: none;
    border: none;
    text-decoration: underline;
    color: rgb(0, 0, 238);
    cursor: pointer
}

table.calender{
        font-size: 13px;
        margin: 3px;
	color: black;
	text-align: left;
}
table.calender,
table.calender th,
table.calender td{
	border: 2px inset;
	border-collapse: collapse;
}
table.calender th{
	text-align: center;
}
table.calender p{
        margin: 0;
}
table.calender td{
        word-break: break-all;
        min-width: 1.5em;
}
table.calender .open{
        background: pink;
}
table.calender .open .day{
        font-weight: bold;
}
table.calender .close{
        background: silver;
}
table.calender .date .checks,
table.calender .date .day{
        display: inline;
}
table.calender .comment{
        display: none;
}
table.calender .cell .checks:before{
        content: "\00a0"
}

